import { useEffect, useState } from 'react'




const Filter = ({ userTokens, setFilteredTokens, searchText, setSearchText }) => {

  const [filterContent, setFilterContent] = useState({
          nameInputArray: [],
          sportInputArray: [],
          rarityLevelInputArray: [],
          seriesInputArray: []
        })







  useEffect(() => {
    let content = {nameInputArray: [], sportInputArray: [], rarityLevelInputArray: [], seriesInputArray: []}

    content.nameInputArray = [...new Set(userTokens.map(item => item.name))]
    content.sportInputArray = [...new Set(userTokens.map(item => item.sport))]
    content.rarityLevelInputArray = [...new Set(userTokens.map(item => item.series))]
    content.seriesInputArray = [...new Set(userTokens.map(item => item.rarityLevel))]
    

    setFilterContent(content)
    setFilteredTokens(userTokens)
  }, [setFilteredTokens, userTokens])
  





  return (
    <section className="w-full max-w-3xl">
      <input type="text"
        className="w-full bg-transparent border border-gray-500 focus:border-gray-200 focus:outline-none rounded-md text-sm py-2 px-4"
        placeholder="Search for player"
        value={searchText}
        list="tokenCategories"
        onChange={(e) => setSearchText(e.target.value)}
      />

      <datalist id="tokenCategories">
        <select id="" className="">
          <optgroup label="Name of Players">
            {filterContent.nameInputArray.map((playerName, index) => (
              <option key={index} value={playerName}>Name of Player</option>
            ))}
          </optgroup>
          
          <optgroup label="Rarity of Token">
            {filterContent.sportInputArray.map((playerSport, index) => (
              <option key={index} value={playerSport}>Sport of Token</option>
            ))}
          </optgroup>
          
          <optgroup label="Rarity of Token">
            {filterContent.rarityLevelInputArray.map((playerRarity, index) => (
              <option key={index} value={playerRarity}>Rarity of Token</option>
            ))}
          </optgroup>
          
          <optgroup label="Series">
            {filterContent.seriesInputArray.map((seriesName, index) => (
              <option key={index} value={seriesName}>Series</option>
            ))}
          </optgroup>
        </select>
      </datalist>
    </section>
  )
}

export default Filter