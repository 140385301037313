import { useState } from "react";
import SeriesCard from "../components/SeriesCard";


const accordionItems = [
  { question: 'What is MinoNFT?',
    answer: "Mino is named after the late Carmine \"Mino\" Raiola, a super agent representing players such as Paul Pogba, Zlatan Ibrahimovic, Erling Haaland, the infamous Balotelli, and even Dennis Bergkamp. <p>The Mino NFT will be a set of unexhaustive collectibles of all current sports personalities. Like Mino Raiola, every collector will become an agent, representing some of the top athletes in the world, not just limited to European football, but also in other sports and leagues such as the NBA, NFL, MLB, and so on.</p> <p>Aside from obtaining the bragging rights of managing some of the top athletes in the world, every superagent holding a Mino NFT will stand to gain when the sports personnel represented by the agent performs exceptionally in their respective sports. Say, your managed client just won the Champions League, and also the Ballon d'Or, you'll be able to receive prizes in USDC as well!</p> <p>This reward structure is possible because Mino was created by Robinos, a sports prediction platform powered by smart contracts. A portion of the prize pool gathered in a major prediction event hosted by Robinos, such as the EPL, Champions League, and F1 races will be set aside, and used to reward the Miro NFT holders whenever a reward has to be given out at the end of the season, end of the tournament, or even end of the month when your player has won the player of the month!'</p>"
  },
  { question: 'What is MinoDAO?',
    answer: "MinoDAO is a decentralized autonomous organization that allows members to be eligible to a share of profits in our treasury. The aim of MinoDAO is primarily to help the project achieve adoption, either through Robinos or MinoNFT. "
  },
  { question: 'How do I buy MinoNFTs?',
    answer: "MinoNFTs can be purchased on MintedVodka. "
  },
  { question: 'How do I join MinoDAO?',
    answer: "To join MinoDAO, you need to first acquire some $RBN on TelosEVM. You can buy $RBN on Swapsicle. Once you have $RBN, they can be used to purchase the MinoDAO membership."
  },
  { question: 'What are the benefits of joining MinoDAO?',
    answer: "As a member of MinoDAO, you stand to benefit from the profits of Robinos and get a cut of the treasury quarterly. Honorary members of MinoDAO can also participate in the governance of the DAO and help shape its future direction. MinoDAO members and Honorary members will share at least 3% & 12% of the treasury respectively every quarter."
  },
  { question: 'How are investment decisions made in MinoDAO?',
    answer: "All investment decisions in MinoDAO are made by the founders & honorary members at this stage. A more decentralized approach will be adopted when we have more stakeholders in MinoDAO."
  },
  { question: 'How do I get involved with MinoDAO governance?',
    answer: "MinoDAO governance is open to honorary members and founders only. Members can propose changes to the governance structure or suggest new strategies, and they will be discussed among the founders and honorary members. Honorary members will be invited for outstanding MinoDAO members with valuable contributions to the growth of products under the MinoDAO umbrella. New honorary members will be expected to buy-in using $RBN."
  },
  { question: 'Is MinoNFT and MinoDAO safe?',
    answer: "MinoNFT and MinoDAO are built on the TelosEVM which is a secure and decentralized platform. There is a treasury involved that is powered by the Telos SAFE, with honorary members as keyholders to the SAFE aside from the founders. However, as with any investment in cryptocurrency, there is always a risk involved. It is important to do your own research and invest responsibly."
  },
]



export const LandingPage = ({ eventSeries, mintNFTToken }) => {

  const [accordionToShow, setAccordionToShow] = useState(1)







  return (
    <div className="space-y-14">
      {/* HERO SECTION */}
      <section className="relative z-0 grid grid-cols-12 gap-y-8 sm:gap-4">
        <aside className="z-10 col-span-12 sm:col-span-2">
          <div className="w-full max-w-md py-20 my-auto space-y-10 sm:absolute top-1/4 h-fit sm:py-0">
            <div className="space-y-4">
              <h1 className="text-5xl font-bold sm:text-6xl">BECOME A SPORTS AGENT <span className="text-whiskey-400">TODAY</span></h1>

              <p className="text-sm">Like Mino Raiola, every collector will become an agent, representing some of the top athletes in the world.</p>
            </div>

            <div className="text-whiskey-400">
               <a href="/portfolio" target="_blank" className={`py-3 space-x-3 text-lg font-normal`}>
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                <span className="">See Full Collection</span>
              </a>
            </div>
          </div>
        </aside>

        <aside className="col-span-12 sm:col-span-10 h-[450px] sm:h-screen" style={{ background:"url('/assets/gif/heroImage.gif')", backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundPosition:"left" }}>
        </aside>
      </section>

      {eventSeries.map((seriesName, index) => (
        <SeriesCard
          seriesName={seriesName}
          cardIndex={index}
          mintNFTToken={mintNFTToken}
        />
      ))}

      {/* MINODAO SECTION */}
      <section className="grid items-center grid-cols-12 px-4 py-6 sm:px-6 sm:py-12 gap-x-6 gap-y-10">
        <aside className="w-full max-w-lg col-span-12 sm:col-span-7">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-4 rounded h-36 sm:rounded-xl" style={{ background:"url('/assets/images/number7.png')", backgroundSize:"cover", backgroundPosition:"center", backgroundRepeat:"no-repeat" }} ></div>
            
            <div className="col-span-4 rounded h-36 sm:rounded-xl" style={{ background:"url('/assets/logo/mino_logo.png')", backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat" }} ></div>
            
            <div className="col-span-4 rounded h-36 sm:rounded-xl" style={{ background:"url('/assets/images/number1.png')", backgroundSize:"cover", backgroundPosition:"center", backgroundRepeat:"no-repeat" }} ></div>
            
            <div className="col-span-4 rounded h-36 sm:rounded-xl" style={{ background:"url('/assets/logo/mino_logo.png')", backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat" }} ></div>
            
            <div className="col-span-4 rounded h-36 sm:rounded-xl" style={{ background:"url('/assets/images/number5.png')", backgroundSize:"cover", backgroundPosition:"center", backgroundRepeat:"no-repeat" }} ></div>
            
            <div className="col-span-4 rounded h-36 sm:rounded-xl" style={{ background:"url('/assets/logo/mino_logo.png')", backgroundSize:"contain", backgroundPosition:"center", backgroundRepeat:"no-repeat" }} ></div>
          </div>
        </aside>


        <aside className="col-span-12 space-y-6 sm:col-span-5">
          <div className="space-y-4 text-4xl font-bold text-center uppercase">
            <h1 className="text-whiskey-400">SOLD OUT!</h1>
            <h2 className="">MinoDAO</h2>
          </div>

          <div className="w-full max-w-[12rem] mx-auto text-center space-y-4">
            <span className="w-full"> Coming Soon </span>
            
            <h6 className="w-full">
              {/* <a href="/" className="block py-3 outline-btn">About Collection</a> */}
              {/* <button className={`solid-btn w-full py-3 ${1 && 'pointer-events-none opacity-70'}`} */}
              <button className={`solid-btn w-full py-3 pointer-events-none opacity-70`}
                onClick={mintNFTToken}
              >MintDAO</button>
            </h6>
          </div>
        </aside>
      </section>

      {/* FAQ SECTION */}
      <section className="w-full max-w-5xl mx-auto space-y-5">
        {accordionItems.map((accordionItem, index) => (
          <div className="px-4 bg-white bg-opacity-10 sm:px-6 rounded-xl">
            <div className={`flex items-center justify-between cursor-pointer py-6 ${accordionToShow === index + 1 && 'border-b'}`} onClick={() => setAccordionToShow(index + 1)}>
              <h1 className="font-bold">{accordionItem.question}</h1>

              <i className="fas fa-plus bg-black rounded-md text-lg px-1.5"></i>
            </div>

            <div className={`text-sm transition-all duration-300 overflow-hidden space-y-3 ${accordionToShow !== index + 1 ? 'h-0' : 'py-6'}`} dangerouslySetInnerHTML={{ __html: accordionItem.answer}} />
          </div>
        ))}
      </section>
    </div>
  )
}


export default LandingPage