import { useState } from "react";
import { daoMintToken, daoApproveSpend } from "../appCore/minoDAOFunctions/writeWeb3Functions"
import { MINO_DAO_SALES_CONTRACT_ADDRESS } from "../appCore/minoConfigFiles/addresses";



const MinoDAOModal = ({ walletIsConnected, minoDAOObj, showMinoDAOPanel, setMinoDAOShowPanel, spendIsApproved, setSpendIsApproved, reloadPage, setReloadPage }) => {

  const [openSteps, setOpenSteps] = useState(true)




  return (
    <div className={`fixed top-0 right-0 px-2 w-screen h-screen flex items-center justify-center z-10 bg-white/20 transition-all duration-200 ${showMinoDAOPanel ? "scale-100" : "delay-300 scale-0"}`}>

      <div className={`w-full max-w-4xl bg-[#212529] transition-all duration-200 ${showMinoDAOPanel ? "delay-300 translate-y-0" : "-translate-y-full"}`}>
        <section className="flex items-center justify-between col-span-12 px-2 py-1 bg-whiskey-900">
          <p className="text-lg">Entry to the MinoDAO costs 15,000 $RBN per membership</p>

          <div className="px-2 cursor-pointer" onClick={() => setMinoDAOShowPanel(!showMinoDAOPanel)}>
            <i className="text-2xl text-white fas fa-xmark"></i>
          </div>
        </section>

        <section className={`flex flex-col sm:grid sm:grid-cols-12 sm:divide-x h-[75vh]`}>
          <aside className="order-2 w-full px-2 pb-4 border-t sm:order-1 sm:col-span-4 sm:border-none">
            <section className="h-full sm:flex sm:flex-col">
              <div className="flex items-center text-sm sm:text-lg sm:flex-1">
                <section className="relative space-y-3 sm:space-y-4">
                  <div className="absolute flex items-center justify-center w-6 h-6 bg-black rounded-full sm:hidden -top-3 right-3" onClick={() => setOpenSteps(!openSteps)}>
                    <i className={`fas fa-caret-up transition-all duration-500 ${openSteps ? "rotate-180" : "rotate-0"}`}></i>
                  </div>

                  <div className={`text-sm space-y-3.5 py-1 sm:py-6 overflow-hidden transition-all duration-300 ${openSteps ? "sm:h-fit h-40" : "sm:h-fit h-0"}`}>
                    <div className="flex gap-x-2">
                      <span className="">1.</span>
                      <span className="">“Approve Spend” to approve the contract interacting with your $RBN (note: enter a custom number larger than 15,000)</span>
                    </div>

                    <div className="flex gap-x-2">
                      <span className="">2.</span>
                      <span>Select an available number to get your unique MinoDAO id</span>
                    </div>

                    <div className="flex gap-x-2">
                      <span className="">3.</span>
                      <span>Approve the buy transaction in MetaMask.</span>
                    </div>
                  </div>
                  
                  <div className="">
                    <p className=""> Approved spend limit: </p>
                    <span className="font-light sm:text-3xl">{spendIsApproved.toFixed(2) }</span>
                  </div>
                </section>
              </div>

              <div className="w-full p-1 sm:p-0 sm:space-y-3">
                {/* <input
                  type="text"
                  className="w-3/5 text-black outline-none sm:w-full sm:p-2"
                  placeholder={spendIsApproved ? "Amount to Approve" : "Tokens to Mint"}
                  value={numberOfTokensToBuy}
                  onChange={(e) => {
                    if (e.target.value > -1 || e.target.value === "") {
                      setNumberOfTokensToBuy(e.target.value)
                    }
                  }}
                /> */}

                <button className="w-full py-3 rounded solid-btn" onClick={() => {
                  daoApproveSpend(10).then(approved => {
                    if (approved === true) {
                      setSpendIsApproved(approved)
                    }
                  })
                }}>Approve Spend</button>
              </div>
            </section>
          </aside>
          
          <aside className="flex-1 order-1 pb-8 overflow-auto sm:order-2 sm:col-span-8 no-scroll-bar">
            <div className="px-2 py-4 text-sm sm:px-4">
              Click on any available number you'd like to be your NFT id
            </div>

            <div className="flex flex-wrap gap-3 px-2 sm:px-4">
              {walletIsConnected === true ?
                minoDAOObj.length && typeof minoDAOObj === "object" ?
                  minoDAOObj?.map((minoDAOItem, index) => (
                    <label
                      key={index}
                      className={`w-14 h-12 flex items-center justify-center font-semibold rounded-md bg-gray-700 hover:border cursor-pointer ${minoDAOItem.owner !== MINO_DAO_SALES_CONTRACT_ADDRESS && "opacity-30 pointer-events-none"}`}
                      onClick={() => {
                        daoMintToken(minoDAOItem.tokenId).then(approved => {
                          if (approved === true) {
                            setReloadPage(reloadPage + 1)
                          }
                        })
                      }}
                    > {minoDAOItem.tokenId} </label>
                  ))
                :
                  <div className="col-span-12 text-lg font-medium text-center">
                    There are no tokens to be minted at this time. Check again later
                  </div>
              :
                <div className="col-span-12 text-lg font-medium text-center">
                  You are not connected to Metamask
                </div>
              }
            </div>
          </aside>
        </section>
      </div>
    </div>
  )
}

export default MinoDAOModal
