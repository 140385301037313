export const HALL_OF_FAME_ABI = [
  {
    inputs: [
      {
        internalType: "string",
        name: "rewardName",
        type: "string",
      },
      {
        internalType: "string",
        name: "playerName",
        type: "string",
      },
      {
        internalType: "string",
        name: "sportsName",
        type: "string",
      },
      {
        internalType: "contract IERC20",
        name: "rewardToken",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "rewardAmount",
        type: "uint256",
      },
    ],
    name: "newReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract MinoToken",
        name: "minoToken_",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllRewards",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "rewardName",
            type: "string",
          },
          {
            internalType: "string",
            name: "playerName",
            type: "string",
          },
          {
            internalType: "string",
            name: "sportsName",
            type: "string",
          },
          {
            internalType: "contract IERC20",
            name: "rewardToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "rewardAmount",
            type: "uint256",
          },
        ],
        internalType: "struct MinoTokenReward.Reward[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "rewardName",
        type: "string",
      },
      {
        internalType: "string",
        name: "playerName",
        type: "string",
      },
    ],
    name: "getRewardWinners",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "playerName",
        type: "string",
      },
      {
        internalType: "string",
        name: "sportsName",
        type: "string",
      },
      {
        internalType: "contract IERC20",
        name: "rewardToken",
        type: "address",
      },
    ],
    name: "getTotalPlayerReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getUserRewards",
    outputs: [
      {
        components: [
          {
            internalType: "string",
            name: "rewardName",
            type: "string",
          },
          {
            internalType: "string",
            name: "playerName",
            type: "string",
          },
          {
            internalType: "string",
            name: "sportsName",
            type: "string",
          },
          {
            internalType: "contract IERC20",
            name: "rewardToken",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "rewardAmount",
            type: "uint256",
          },
        ],
        internalType: "struct MinoTokenReward.Reward[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
