import { checkWalletChainAndSwitch } from '../appCore/globalFunctions'



const ChainChangedPopUp = ({ chainName, chainId }) => {
  return (
    <section className={`bg-black bg-opacity-30 h-full w-full fixed top-0 left-0 z-40 flex items-center justify-center px-1 font-medium`}>
      <figure className="bg-white text-gray-800 max-w-sm p-4 rounded text-sm">
        <p>
          System detected a change in your metamask network. This will cause grave error on this page, please click the button below to switch back to <span className="font-bold"> {chainName} </span>
        </p>
        
        <div className="text-center mt-6">
          <label className="solid-btn hover-outline px-3 py-2 text-base cursor-pointer" onClick={() => {
            checkWalletChainAndSwitch(chainId)
          }}>Switch To {chainName}</label>
        </div>
      </figure>
    </section>
  )
}

export default ChainChangedPopUp