import { useEffect, useState } from "react";
import Filter from "../components/Filter";
import NFTImageCard from "../components/NFTImageCard";
import { nftGetAllUserTokens } from "../appCore/minoNFTFunctions/readWeb3Functions";










export const PortfolioPage = ({ walletIsConnected }) => {
  const [userTokens, setUserTokens] = useState([]),
        [searchText, setSearchText] = useState(''),
        [filteredTokens, setFilteredTokens] = useState([])





  useEffect(() => {
    if (walletIsConnected) {
      nftGetAllUserTokens().then(mintedTokens => setUserTokens(mintedTokens))
    }
  }, [walletIsConnected])
  







  return (
    <div className="space-y-8 py-8">
      <Filter
        userTokens={userTokens}
        setFilteredTokens={setFilteredTokens}
        searchText={searchText}
      />
    
      <aside className='min-h-screen grid grid-cols-12 gap-x-6 gap-y-12'>
        { walletIsConnected === true ?
          filteredTokens.length > 0 ?
            filteredTokens.map((userToken, index) => (
              <NFTImageCard
                key={index}
                userToken={userToken}
                displayPage={""}
                searchText={searchText}
                setSearchText={setSearchText}
              />
            ))
          :
            <div className="col-span-12 text-lg font-medium">
              You do not have any NFT
            </div>
        :
          <section className="col-span-12 font-medium text-lg">
            You are not connected to Metamask
          </section>
        }
      </aside>
    </div>
  )
}


export default PortfolioPage