import { getConnectedWalletAddress, initContract } from "../globalFunctions";
import {
  MINO_NFT_CONTRACT_ADDRESS,
  MINO_REWARD_CONTRACT_ADDRESS,
  USDC_CONTRACT_ADDRESS
} from "../minoConfigFiles/addresses";
import { HALL_OF_FAME_ABI } from "../minoConfigFiles/HALL_OF_FAME_ABI";
import { MINO_NFT_ABI } from "../minoConfigFiles/MINO_NFT_ABI";









export const nftGetAllUserTokens = async () => {
  try {
    let connectedAddress = await getConnectedWalletAddress(),
        mainContract = await initContract(MINO_NFT_ABI, MINO_NFT_CONTRACT_ADDRESS),
        response = await mainContract.methods.getAllUserTokens(connectedAddress).call();
        // response = await mainContract.methods.getAllUserTokens('0xc3357bf63657C8fE9B8d6CFB811b809694D5EB3E').call();
    
    return response;
  } catch (nftGetAllUserTokens) {
    console.log("nftGetAllUserTokens", nftGetAllUserTokens);
    return nftGetAllUserTokens.message;
  }
};





// RETURNS NUMBER OF NFTs USER CAN MINT
export const nftGetUserMintsPerSeries = async (seriesName) => {
  try {
    let connectedAddress = await getConnectedWalletAddress(),
        mainContract = await initContract(MINO_NFT_ABI, MINO_NFT_CONTRACT_ADDRESS),
        minted = await mainContract.methods.userMintedInSeries(connectedAddress, seriesName).call(),
        whitelist = await mainContract.methods.getUserMintsForSeries(connectedAddress, seriesName).call();
    
    // console.log(mainContract.methods);
    return {
      userMinted: minted,
      userWhitelist: whitelist
    };
  } catch (nftGetUserMintsPerSeriesError) {
    console.log("nftGetUserMintsPerSeriesError", nftGetUserMintsPerSeriesError);
    return nftGetUserMintsPerSeriesError.message;
  }
};





// RETURNS NFT INFORMATION PER TOKEN
export const nftGetTokenData = async (tokenID) => {
  try {
    let mainContract = await initContract(MINO_NFT_ABI, MINO_NFT_CONTRACT_ADDRESS),
        response = await mainContract.methods.getTokenData(tokenID).call();
    
    // console.log(mainContract.methods);
    return response;
  } catch (nftGetTokenDataError) {
    console.log("nftGetTokenDataError", nftGetTokenDataError);
    return nftGetTokenDataError.message;
  }
};


































export const nftGetUserReward = async () => {
  try {
    let userAddress = await getConnectedWalletAddress(),
        mainContract = await initContract(HALL_OF_FAME_ABI, MINO_REWARD_CONTRACT_ADDRESS),
        response = await mainContract.methods.getUserRewards(userAddress).call();
        // response = await mainContract.methods.getUserRewards('0xc3357bf63657C8fE9B8d6CFB811b809694D5EB3E').call();

    // console.log(response);

    return response;
  } catch (nftGetUserRewardError) {
    console.log('nftGetUserRewardError', nftGetUserRewardError);
    return nftGetUserRewardError.message;
  }
};





// RETURNS NFT INFORMATION PER TOKEN
export const nftGetTotalPlayerReward = async (playerName, sportsName) => {
  try {
    let mainContract = await initContract(HALL_OF_FAME_ABI, MINO_REWARD_CONTRACT_ADDRESS)
    let response = await mainContract.methods.getTotalPlayerReward(playerName, sportsName, USDC_CONTRACT_ADDRESS).call();

    // console.log(playerName, sportsName);
    return response;
  } catch (nftGetTotalPlayerRewardError) {
    console.log("nftGetTotalPlayerRewardError", nftGetTotalPlayerRewardError);
    return nftGetTotalPlayerRewardError.message;
  }
};