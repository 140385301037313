

const PageLoading = ({walletIsConnected, isLoading}) => {
  return (
    <section className={`fixed top-0 left-0 ${(walletIsConnected === true || isLoading === false) ? 'h-0 w-0 z-0' : 'h-screen w-screen z-30'}`}>
      <video autoPlay loop muted className='object-cover h-full w-full'>
        <source src="/assets/videos/welcome.mp4#t=02" type='video/mp4' />
        <p className='font-medium text-xl'>
          Page is loading...
        </p>
      </video>
    </section>
  )
}

export default PageLoading