import { toast } from "react-toastify";
import { getConnectedWalletAddress, initContract } from "../globalFunctions";
import { MINO_NFT_CONTRACT_ADDRESS } from "../minoConfigFiles/addresses";
import { MINO_NFT_ABI } from "../minoConfigFiles/MINO_NFT_ABI";








export const nftMintToken = async (eventCode) => {
  let connectedWallet = await getConnectedWalletAddress(),
      mainContract = await initContract(MINO_NFT_ABI, MINO_NFT_CONTRACT_ADDRESS);


  return new Promise((resolve, reject) => {
    mainContract.methods.mintToken(eventCode).send({
      from: connectedWallet,
      gas: 5000000
    })
    .once("transactionHash", (hash) => {
      toast.info("Your mint token transaction is pending confirmation on the block");
      console.log("transactionHash", hash);
    })
    .on('receipt', (receipt) => {
      toast.success('Mint token transaction successfully mined!')
      console.info('Transaction Receipt', receipt);

      return resolve(true);
    })
    .on('error', (nftMintTokenPromiseError) => {
      console.info('nftMintTokenPromiseError', nftMintTokenPromiseError)
      toast.error(nftMintTokenPromiseError.message)
      return reject(false);
    })
  }).catch((nftMintTokenError) => {
    console.log('nftMintTokenError', nftMintTokenError);
    return nftMintTokenError.message;
  })
};