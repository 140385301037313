import { toast } from "react-toastify";
import { getConnectedWalletAddress, initContract, web3 } from "../globalFunctions";
import { MINO_DAO_STANDARD_TOKEN_ADDRESS, MINO_DAO_SALES_CONTRACT_ADDRESS } from "../minoConfigFiles/addresses";
import { USDC_TOKEN_ABI } from "../minoConfigFiles/USDC_TOKEN_ABI";
import { MINO_DAO_SALES_ABI } from "../minoConfigFiles/MINO_DAO_SALES_ABI";









export const daoApproveSpend = async (amountToApprove) => {
  let connectedWallet = await getConnectedWalletAddress(),
      mainContract = await initContract(USDC_TOKEN_ABI, MINO_DAO_STANDARD_TOKEN_ADDRESS),
      approveAmount = web3.utils.toWei(amountToApprove.toString(), 'lovelace');


  return new Promise((resolve, reject) => {
    mainContract.methods.approve(MINO_DAO_SALES_CONTRACT_ADDRESS, approveAmount)
    .send({
      from: connectedWallet,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
    })
    .once("transactionHash", (hash) => {
      toast.info("Your approve spend transaction is pending confirmation on the block");
      console.log("transactionHash", hash);
    })
    .on('receipt', (receipt) => {
      toast.success('Approve spend transaction successfully mined!')
      console.info('Transaction Receipt', receipt);

      return resolve(true);
    })
    .on('error', (nftMintTokenPromiseError) => {
      console.info('nftMintTokenPromiseError', nftMintTokenPromiseError)
      toast.error(nftMintTokenPromiseError.message)
      return reject(false);
    })
  }).catch((daoApproveSpendError) => {
    console.log('daoApproveSpendError', daoApproveSpendError);
    return daoApproveSpendError.message;
  })
};







export const daoMintToken = async (tokensId) => {
  let connectedWallet = await getConnectedWalletAddress(),
      mainContract = await initContract(MINO_DAO_SALES_ABI, MINO_DAO_SALES_CONTRACT_ADDRESS);

  console.log(mainContract.methods);
  return new Promise((resolve, reject) => {
    mainContract.methods.purchaseTokenFromSale(tokensId).send({
      from: connectedWallet,
      gas: 5000000
    })
    .once("transactionHash", (hash) => {
      toast.info("Your mint token transaction is pending confirmation on the block");
      console.log("transactionHash", hash);
    })
    .on('receipt', (receipt) => {
      toast.success('Mint token transaction successfully mined!')
      console.info('Transaction Receipt', receipt);

      return resolve(true);
    })
    .on('error', (nftMintTokenPromiseError) => {
      console.info('nftMintTokenPromiseError', nftMintTokenPromiseError)
      toast.error(nftMintTokenPromiseError.message)
      return reject(false);
    })
  }).catch((nftMintTokenError) => {
    console.log('nftMintTokenError', nftMintTokenError);
    return nftMintTokenError.message;
  })
};