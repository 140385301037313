

const Footer = () => {
  return (
    <section className="flex items-center justify-between py-6">
      <div className="flex items-center justify-center gap-2">
        <i className="text-sm far fa-copyright"></i>
        <span className="text-xs">Copyright - 2022</span>
      </div>

      <div className="space-x-5">
        <a href="https://twitter.com/MinoSportsNFT" target="_blank" title='Mino NFT Twitter' rel="noreferrer" className="text-xl">
          <i className="fab fa-twitter hover:text-blue-500"></i>
        </a>
        
        <a href="https://www.youtube.com/channel/UCABMGvvrxbzgPI3PRCDQC4w" target="_blank" title='Mino NFT YouTube' rel="noreferrer" className="text-xl hover:text-red-600">
          <i className="fab fa-youtube"></i>
        </a>
        
        <a href="https://discord.com/invite/uvbjt5tR4X" target="_blank" title='Mino NFT Discord' rel="noreferrer" className="text-xl hover:text-blue-700">
          <i className="fab fa-discord"></i>
        </a>
        
        <a href="https://robinos.gitbook.io/robinos-tokenized-prediction-utility-platform/" target="_blank" title='Mino NFT Docs' rel="noreferrer" className="text-xl">
          <i className="fas fa-scroll"></i>
        </a>
      </div>
    </section>
  )
}

export default Footer