import { useState, useEffect } from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';

import LandingPage from './pages/LandingPage'
import { checkIfMetamaskIsInstalled, checkWalletChainAndSwitch, connectToMetamask, getConnectedWalletAddress } from './appCore/globalFunctions';
import ChainChangedPopUp from './components/ChainChangedPopUp';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PortfolioPage from './pages/PortfolioPage';
import { nftMintToken } from './appCore/minoNFTFunctions/writeWeb3Functions';
import PageLoading from './components/PageLoading';
import { nftGetUserMintsPerSeries } from './appCore/minoNFTFunctions/readWeb3Functions';
import Footer from './components/Footer';
import MinoDAOModal from './pages/MinoDAOModal';
import NavBar from './components/NavBar';
import { daoGetAllTokens, daoIsApproved } from './appCore/minoDAOFunctions/readWeb3Functions';
import { eventSeries } from './appCore/minoConfigFiles/eventSeries';
// import MarketPage from './pages/MarketPage';


function App() {
  const chainInfo = { hex: '0x28', title: 'Telos', symbol: 'TLOS' },
        [spendIsApproved, setSpendIsApproved] = useState(0),
        [openMetamaskWarning, setOpenMetamaskWarning] = useState(false),
        [showMinoDAOPanel, setMinoDAOShowPanel] = useState(false),
        [connectedWallet, setConnectedWallet] = useState(''),
        [hashedWallet, setHashedWallet] = useState('Connect Wallet'),
        [walletIsConnected, setWalletIsConnected] = useState(false),
        [minoDAOObj, setMinoDAOObj] = useState([]),
        [minoDAOHolding, setMinoDAOHolding] = useState([]),
        [openInstallLinks, setOpenInstallLinks] = useState(false),
        [isLoading, setIsLoading] = useState(true),
        [showChainPopUp, setShowChainPopUp] = useState(false),
        [reloadPage, setReloadPage] = useState(0),
        [mintedToken, setMintedToken] = useState(0),
        [switchedNetwork, setSwitchedNetwork] = useState({
          status: false,
          portfolioMessage: `Please connect to the ${chainInfo.title} mainnet for events in your portfolio`,
          eventMessage: `Please connect to the ${chainInfo.title} mainnet for information about events`,
          teamMessage: `Connect to ${chainInfo.title} mainnet for sale information of team`,
        })






  useEffect(() => {
    checkIfMetamaskIsInstalled().then(isInstalled => {
      if (isInstalled === true) {
        window.ethereum.on('chainChanged', (_chainId) => {
          if (typeof _chainId !== 'undefined') {
            if (_chainId !== chainInfo.hex) { /* METAMASK IS NOT ON THE PREFERRED NETWORK */
              setShowChainPopUp(true)
              setSwitchedNetwork(previousState => ({...previousState, status: false}))
              return true;
            }
          }
          setShowChainPopUp(false)
          setSwitchedNetwork(previousState => ({...previousState, status: true}))
          return false;
        });



        return getConnectedWalletAddress().then(account => {
          if (typeof account !== 'boolean') {
            setConnectedWallet(account)
            setHashedWallet(`${account.slice(0, 6)}...${account.slice(account.length - 4, account.length)}`.toLowerCase());
            setWalletIsConnected(true)

            return checkWalletChainAndSwitch(chainInfo.hex).then(connected => {
              console.log('Network Switched', connected)
              if (connected === true) {
                setSwitchedNetwork(previousState => ({ ...previousState, status: true }))
                
                daoIsApproved().then(isApproved => setSpendIsApproved(isApproved))

                console.error(reloadPage);
                daoGetAllTokens().then(allTokens => {
                  const filterDAO = allTokens.filter(allToken => allToken.owner === account)
                  setMinoDAOHolding(filterDAO)
                  setMinoDAOObj(allTokens)
                })

                return nftGetUserMintsPerSeries(eventSeries[0]).then(userSeries => {
                  if (!isNaN(userSeries?.userWhitelist)) {
                    setMintedToken(userSeries?.userMinted)
                  }
                })
              }
            })
          }

          setIsLoading(false)
        })
      }

      setOpenMetamaskWarning(true)
      return;
    })


    setIsLoading(false)
  }, [chainInfo.hex, walletIsConnected, reloadPage])







  const connectToWallet = () => {
    connectToMetamask().then(metamaskData => {
      if (typeof metamaskData === typeof true) {
        setOpenMetamaskWarning(true)
        return;
      }

      return checkWalletChainAndSwitch(chainInfo.hex).then(connected => {
        setHashedWallet(metamaskData.hashedAccount)
        setWalletIsConnected(true)

        console.log('Network Switched', connected)
        if (connected === true) {
          setSwitchedNetwork(previousState => ({ ...previousState, status: true }))
          return
        }
      })
    });
  }


  const mintNFTToken = (mintableToken) => {
    if (mintableToken > 0) {
      nftMintToken(eventSeries[0]).then((minted) => {
        if (minted === true) {
          setReloadPage(!reloadPage);
          toast.success("1 token successfully minted");
          return;
        }

        toast.error("Failed to mint. Try again");
      });
    } else {
      toast.error("You have not been whitelisted to mint");
    }

    return;
  };



  


  return (
    isLoading === true ?
      <PageLoading walletIsConnected={walletIsConnected} isLoading={isLoading} />
    :
      <div className='bg-[#212529] text-white px-3 sm:p-0'>
        <div className='relative w-full max-w-6xl mx-auto space-y-20'>
          <div className="min-h-screen">
            <NavBar
              connectToWallet={connectToWallet}
              hashedWallet={hashedWallet}
              mintNFTToken={mintNFTToken}
              spendIsApproved={spendIsApproved}
              setSpendIsApproved={setSpendIsApproved}
              showMinoDAOPanel={showMinoDAOPanel}
              setMinoDAOShowPanel={setMinoDAOShowPanel}
              minoDAOHolding={minoDAOHolding}

              connectedWallet={connectedWallet}
              switchedNetwork={switchedNetwork}
            />

            <MinoDAOModal
              reloadPage={reloadPage}
              setReloadPage={setReloadPage}
              walletIsConnected={walletIsConnected}
              spendIsApproved={spendIsApproved}
              minoDAOObj={minoDAOObj}
              showMinoDAOPanel={showMinoDAOPanel}
              setMinoDAOShowPanel={setMinoDAOShowPanel}
            />

            <BrowserRouter>
              <Routes>
                <Route path='/'
                  element={<LandingPage
                    mintNFTToken={mintNFTToken}
                    eventSeries={eventSeries}
                  />}
                />

                <Route path='/portfolio'
                  element={<PortfolioPage
                    hashedWallet={hashedWallet}
                    walletIsConnected={walletIsConnected}
                    mintedToken={mintedToken}
                  />}
                />
              </Routes>
            </BrowserRouter>
          </div>

          <Footer />
        </div>


        {/* METAMASK NOT INSTALLED WARNING */}
        <section className={`bg-black bg-opacity-30 fixed top-0 left-0 overflow-hidden ${openMetamaskWarning === true ? 'h-screen w-screen z-10' : 'h-0 w-0 z-0'} flex items-center justify-center px-1 font-medium`}>
          <figure className="max-w-sm p-4 text-sm text-gray-800 bg-white rounded">
            <h5> System checks could not find metamask installed on this browser. </h5>
            <div className="flex items-center justify-between mt-6">
              <div className="relative">
                <span className="px-3 py-3 cursor-pointer solid-btn" onClick={() => { setOpenInstallLinks(!openInstallLinks) }}>Click to install Metamask</span>

                <figure className={`bg-white shadow-lg text-black w-full absolute top-7 pt-2 flex flex-col divide-y-2 overflow-y-hidden ${openInstallLinks === true ? 'h-36' : 'h-0'}`}>
                  <a href="https://metamask.io/download" target="_blank" rel="noreferrer" className="p-3">For desktop</a>

                  <a href="https://play.google.com/store/apps/details?id=io.metamask&hl=en&gl=US" target="_blank" rel="noreferrer" className="p-3">For android</a>

                  <a href="https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202" target="_blank" rel="noreferrer" className="p-3">For iOS</a>
                </figure>
              </div>

              <label className="outline-btn font-semibold px-3 py-2.5 cursor-pointer" onClick={() => { setOpenMetamaskWarning(false) }}>Close</label>
            </div>
          </figure>
        </section>


        {showChainPopUp === true && <ChainChangedPopUp chainName={chainInfo.title} chainId={chainInfo.hex} />}


        <ToastContainer position="bottom-left" theme="colored" />
      </div>
  );
}


export default App;