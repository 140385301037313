import { useEffect, useState, useRef } from "react";
import { /* nftGetTotalPlayerReward, */ nftGetUserReward } from "../appCore/minoNFTFunctions/readWeb3Functions";
import { decimalizeFigure, truncValue } from "../appCore/miscFunctions";
import { getTokenSymbol } from "../appCore/globalFunctions";



const NFTImageCard = ({ userToken, searchText }) => {

  const [playState, setPlayState] = useState(false),
        [flipCard, setFlipCard] = useState(false),
        [rewardedPlayers, setRewardedPlayers] = useState([]),
        // [rewardAmount, setRewardAmount] = useState(0),
        playVideoRef = useRef('video')






  useEffect(() => {
    // nftGetTotalPlayerReward(userToken.name, userToken.sport).then(reward => setRewardAmount(reward))
    async function fetchRewards() {
      try {
        const userRewards = await nftGetUserReward();

        const enrichedRewards = await Promise.all(
          userRewards
            .filter(reward => reward.playerName === userToken.name)
            .map(async reward => {
              const symbol = await getTokenSymbol(reward.rewardToken);
              return { ...reward, tokenSymbol: symbol };
            })
        );


        // const totalRewardAmount = enrichedRewards.reduce((total, item) => {
        //   const rewardAmount = decimalizeFigure(item.rewardAmount, item.rewardToken);

        //   return total + rewardAmount;
        // }, 0);

        // setRewardAmount(totalRewardAmount / Math.pow(10, 6))

        setRewardedPlayers(enrichedRewards);
      } catch (error) {
        // Handle errors here
        console.error("Error:", error);
      }
    }

    // Call the function to fetch and process rewards
    fetchRewards();
  }, [userToken])
  

  


  return (
    <div
      className={`col-span-12 sm:col-span-3 space-y-2 perspectives ${(userToken["name"].toLowerCase().includes(searchText.toLowerCase()) ||
                  userToken["rarityLevel"].toLowerCase().includes(searchText.toLowerCase()) ||
                  userToken["sport"].toLowerCase().includes(searchText.toLowerCase()) ||
                  userToken["series"].toLowerCase().includes(searchText.toLowerCase())) === false && "hidden"}`}
    >
      <aside className={`relative transition-all duration-1000 preserve-3d`} style={{ transform: `${flipCard ? "rotateY(180deg)" : "rotateY(0deg)"}`}}>
        {/* FRONT CARD FOR DISPLAYING PLAYER'S INFO */}
        <div className="pt-3 pb-4 px-4 h-full w-full flex flex-col bg-black/40 rounded-lg space-y-3 backface-hidden">
          <section className="flex items-center justify-between text-sm font-medium">
            <h1 className="">{userToken.name}</h1>

            {playState === false ?
              // PLAY VIDEO
              <i className="far fa-play-circle cursor-pointer" onClick={() => {
                setPlayState(true)
                playVideoRef.current.play()
              }}></i>
            :
              // PAUSE VIDEO
              <i className="far fa-pause-circle cursor-pointer" onClick={() => {
                setPlayState(false)
                playVideoRef.current.pause()
                playVideoRef.current.currentTime = 0;
              }}></i>
            }
          </section>

          <section className="flex-1">
            <video loop muted className='rounded-lg object-cover h-full' ref={playVideoRef}>
              <source src={userToken.tokenUri} type='video/mp4' />
              <p>
                Your browser doesn't support HTML video. Here is a
                <a href={`${userToken.tokenUri}`} download>link to the video</a> instead.
              </p>
            </video>
          </section>

          <section className="grid grid-cols-12 gap-y-3 flex-1">
            <div className="col-span-8 flex items-center gap-x-1.5">
              <i class="fa-solid fa-gem opacity-40"></i>

              <aside className="text-sm sm:text-xs">
                <h6 className="text-xs sm:text-xxs opacity-40">Rarity</h6>
                <h5 className="font-medium">{userToken.rarityLevel}</h5>
              </aside>
            </div>
            
            <div className="col-span-4 flex items-center gap-x-1.5">
              <i class="fa-solid fa-hashtag opacity-40"></i>

              <aside className="text-sm sm:text-xs">
                <h6 className="text-xs sm:text-xxs opacity-40">Number</h6>
                <h5 className="font-medium">{userToken.tokenId}</h5>
              </aside>
            </div>
            
            <div className="col-span-12 flex items-center gap-x-1.5">
              <i class="fa-solid fa-trophy opacity-40"></i>

              <aside className="text-sm sm:text-xs">
                <h6 className="text-xs sm:text-xxs opacity-40">Series</h6>
                <h5 className="font-medium">{userToken.series}</h5>
              </aside>
            </div>
          </section>
        </div>

        {/* BACK CARD FOR DISPLAYING PLAYER'S REWARDS */}
        <div className="pb-14 absolute top-0 bg-black/40 rounded-lg overflow-hidden backface-hidden w-full h-full max-h-full" style={{ transform:"rotateY(180deg)"}}>
          <h6 className="py-2 px-4 shadow shadow-gray-800">{(userToken.name).split(' ')[0]}</h6>

          <div className="overflow-auto p-4 h-full space-y-4 no-scroll-bar">
            {rewardedPlayers.map((rewardedPlayer, index) => (
              <section key={index} className="transition-all duration-300 text-sm grid grid-cols-12">
                <h6 className="col-span-8"> {rewardedPlayer.rewardName} </h6>

                <div className="col-span-4 text-right">
                  <h6 className="text-lg font-semibold">
                    {truncValue( decimalizeFigure(rewardedPlayer.rewardAmount, rewardedPlayer.rewardToken), 2)}
                  </h6>
                  
                  <sup className="text-xxs"> { rewardedPlayer?.tokenSymbol } </sup>
                </div>
              </section>
            ))}

            <h6 className="text-xs text-center font-semibold italic text-gray-500">- End -</h6>
          </div>
        </div>
      </aside>


      <aside className={`border rounded-md px-4 py-1 flex justify-between items-center gap-x-1.5`}>
        {/* <div className="flex items-center gap-x-1.5">
          <i class="fa-solid fa-coins opacity-40"></i>

          <div className="text-sm sm:text-xs">
            <h6 className="text-xs sm:text-xxs opacity-40">Earnings</h6>
            <h5 className="font-medium">{ truncValue(rewardAmount, 2) } USDC</h5>
          </div>
        </div> */}

        <div className="text-sm cursor-pointer" onClick={() => setFlipCard(!flipCard)}>
          See {rewardedPlayers.length} Earnings
        </div>
      </aside>
    </div>
  )
}

export default NFTImageCard