import { toast } from 'react-toastify';
import { USDC_CONTRACT_ADDRESS } from './minoConfigFiles/addresses';






export const getEventROI = (id, arrayToLoop) => {
  let eventROI = {}
  
  arrayToLoop?.forEach((element) => {
    if (element.id === parseInt(id)) {
      eventROI['minROI'] = element.minROI;
      eventROI['maxROI'] = element.maxROI;
    }
  });
  return eventROI;
};



export const getEventData = (id, arrayToLoop, partToGet) => {
  let eventCode = ""
  
  arrayToLoop?.forEach((element) => {
    if (element.id === parseInt(id)) {
      eventCode = element[partToGet];
    }
  });
  return eventCode;
};




export const calculateTimeLeft = (saleEndDate) => {
  let difference = +(saleEndDate * 1000) - +new Date();

  let timeLeft = {
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  };

  if (difference > 0) {
    timeLeft = {
      days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0'),
      hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
      minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
      seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
    };
  }

  return timeLeft;
}



export const addTeamTokenToMetamask = async (team, tokenDecimal) => {
  let metamaskOptions = {
    address: team.tokenAddress,
    symbol: team.metamaskSymbol,
    decimals: tokenDecimal
  }

  return new Promise((resolve, reject) => {
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: metamaskOptions
      },
    }).then((tokenAdded) => {
      if (tokenAdded) {
        toast.success(`${team.metamaskSymbol} successfully added to wallet`)
        console.info(tokenAdded.suggestedAssetMeta.status);
      } else {
        console.info(tokenAdded);
        toast.error(`${team.metamaskSymbol} could not be added to wallet at this time`)
      }
    }).catch((ethError) => {
      toast.error(ethError.message)
      console.log(ethError.message);
    })
  }).catch(error => {
    toast.error(error.message)
  })
}



export const truncValue = (valueToTruncate, decimalPlaces) => {
  const truncated = Math.trunc(valueToTruncate * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)

  return truncated;
}



export const decimalizeFigure = (SCValue, tokenAddress) => {
  if (tokenAddress.toLowerCase() === USDC_CONTRACT_ADDRESS.toLowerCase()) {
    return SCValue / Math.pow(10, 6);
  }

  return SCValue / Math.pow(10, 18);
}
