import { toast } from 'react-toastify';



const NavBar = ({ connectToWallet, hashedWallet, mintNFTToken, showMinoDAOPanel, setMinoDAOShowPanel, minoDAOHolding}) => {
  return (
    <div className={`${window.location.pathname === '/' && 'sm:absolute top-0 z-10'} w-full py-4 flex flex-col sm:flex-row items-center justify-between text-sm font-medium`}>
      <aside className="">
        <a href="/" className="flex items-center gap-2">
          <img src="/assets/logo/mino_logo.png" alt="Mino NFT Logo" className="h-10" />
          <img src="/assets/logo/mino_text.png" alt="Mino NFT Logo" className="h-5" />
        </a>
      </aside>

      <aside className="flex flex-row items-center justify-between py-10 gap-x-10 sm:py-0">
        <div className="space-x-8">
          <a href="https://robinos.finance" target="_blank" rel="noreferrer" className="underline underline-offset-4 decoration-2 sm:no-underline">Robinos</a>

          <a href="https://www.notion.so/robinospredict/e0d058ccfaf84776a4097b76c0d203a3?v=111699bd710c4edea8aebc555e1204f3&pvs=4" target='_blank' rel='noreferrer' className="underline underline-offset-4 decoration-2 sm:no-underline">Docs</a>

          <a href="/portfolio" className="underline underline-offset-4 decoration-2 sm:no-underline">My Players</a>
          {/* <a href="/market" className="">Market</a> */}
          {/* <a href="/rewards" className="">Rewards</a> */}
          <span className="underline cursor-pointer underline-offset-4 decoration-2 sm:no-underline" onClick={() => toast.info('Event will return soon')}> MinoDAO </span>
          {/* <span className="underline cursor-pointer underline-offset-4 decoration-2 sm:no-underline" onClick={() => setMinoDAOShowPanel(!showMinoDAOPanel)}> MinoDAO </span> */}
        </div>


        <button className="outline-btn font-normal px-2 py-1.5" onClick={connectToWallet}>
          <div className="flex items-center justify-between gap-x-1">
            <span className="text-sm">{hashedWallet}</span>
            {minoDAOHolding.length ?
              <img src="/assets/logo/mino_logo.png" alt="Mino NFT Logo" className="h-4" />
            :
              ""
            }
          </div>
        </button>
      </aside>
    </div>
  )
}

export default NavBar