import { useEffect, useRef, useState } from 'react'
import { nftGetUserMintsPerSeries } from '../appCore/minoNFTFunctions/readWeb3Functions'

const SeriesCard = ({ cardIndex, seriesName, mintNFTToken }) => {
  const [mintableToken, setMintableToken] = useState(0)
  const sectionRef = useRef(null);
  const seriesDirectory = seriesName.replace(/ /g, '_').toLowerCase();



  useEffect(() => {
    nftGetUserMintsPerSeries(seriesName).then(userSeries => {
      if (!isNaN(userSeries?.userWhitelist)) {
        setMintableToken(userSeries?.userWhitelist - userSeries?.userMinted)
      }
    })
  }, [seriesName])
  

  const handleMouseEnter = () => {
    const videos = sectionRef.current.querySelectorAll('video');
    videos.forEach(video => video.play());
  };

  const handleMouseLeave = () => {
    const videos = sectionRef.current.querySelectorAll('video');
    videos.forEach(video => {
      video.pause();
      video.currentTime = 0;
    });
  };


  return (
    <section
      ref={sectionRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="overflow-hidden bg-zinc-900 sm:-translate-y-16 rounded-2xl group"
      style={{ background: `url(/assets/bgImages/${seriesDirectory}.png)`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
    >
      <section
        className="grid items-center grid-cols-6 px-4 py-6 bg-zinc-900/80 group-hover:bg-transparent sm:px-6 sm:py-12 sm:grid-cols-12 gap-x-6 gap-y-10"
      >
        <aside className={`w-full max-w-lg col-span-6 ${cardIndex % 2 === 0 ? 'sm:order-1' : 'sm:order-2'}`}>
          <div className="grid grid-cols-12 gap-4">
            {[1,2,3,4,5,6].map((arrayItem, index) => (
              <video key={index} loop muted className='col-span-4 rounded h-36 sm:rounded-xl'>
                <source src={`/assets/videos/${seriesDirectory}/${arrayItem}.mp4`} type='video/mp4' />
                <p>
                  Your browser doesn't support HTML video. Here is a
                  <a href={`/assets/videos/${seriesDirectory}/${arrayItem}.mp4`} download>link to the video</a> instead.
                </p>
              </video>
            ))}
          </div>
        </aside>

        <aside className={`space-y-14 col-span-6 ${cardIndex % 2 === 0 ? 'sm:order-2' : 'sm:order-1'}`}>
          <div className="space-y-3 text-3xl font-bold text-center">
            <h1 className={`uppercase ${cardIndex === 0 ? 'text-mino-pink-400' : 'text-whiskey-400'}`}>{ cardIndex === 0 ? 'Minting Now' : 'Sold Out' }</h1>
            <h2 className={`uppercase`}>{seriesName}</h2>

            <div className="">
              <a href="/portfolio" target="_blank" className={`py-3 space-x-3 text-base font-normal ${cardIndex === 0 ? 'text-mino-pink-400' : 'text-whiskey-400'}`}>
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
                <span className="">See Full Collection</span>
              </a>
            </div>
          </div>

          <div className="w-full max-w-[12rem] mx-auto text-center space-y-4">
            <button className={`solid-btn ${cardIndex === 0 && 'bg-mino-pink-400'} w-full py-3 ${mintableToken < 1 && 'pointer-events-none opacity-70'}`}
              onClick={() => mintNFTToken(mintableToken)}
            >Mint NFT</button>
            <span className="text-sm">You can mint {mintableToken} tokens</span>
          </div>
        </aside>
      </section>
    </section>
  )
}

export default SeriesCard