import { getConnectedWalletAddress, initContract } from "../globalFunctions";
import { MINO_DAO_ABI } from "../minoConfigFiles/MINO_DAO_ABI";
import { USDC_TOKEN_ABI } from "../minoConfigFiles/USDC_TOKEN_ABI";
import {
  MINO_DAO_CONTRACT_ADDRESS,
  MINO_DAO_SALES_CONTRACT_ADDRESS,
  MINO_DAO_STANDARD_TOKEN_ADDRESS
} from "../minoConfigFiles/addresses";









export const daoIsApproved = async () => {
  try {
    let connectedWallet = await getConnectedWalletAddress(),
        mainContract = await initContract(USDC_TOKEN_ABI, MINO_DAO_STANDARD_TOKEN_ADDRESS),
        response = await mainContract.methods.allowance(connectedWallet, MINO_DAO_SALES_CONTRACT_ADDRESS).call();
        // response = await mainContract.methods.getAllTokens('0xc3357bf63657C8fE9B8d6CFB811b809694D5EB3E').call();
    
    // console.log(mainContract.methods);
    // console.log(response / Math.pow(10, 6));
    return response / Math.pow(10, 6);
  } catch (daoIsApproved) {
    console.log("daoIsApproved", daoIsApproved);
    return daoIsApproved.message;
  }
};






export const daoGetAllTokens = async () => {
  try {
    let mainContract = await initContract(MINO_DAO_ABI, MINO_DAO_CONTRACT_ADDRESS),
        response = await mainContract.methods.getAllTokensData().call();
        // response = await mainContract.methods.getAllTokens('0xc3357bf63657C8fE9B8d6CFB811b809694D5EB3E').call();
    
    // console.log(mainContract.methods);
    // console.log(response);
    return response;
  } catch (daoGetAllTokens) {
    console.log("daoGetAllTokens", daoGetAllTokens);
    return daoGetAllTokens.message;
  }
};